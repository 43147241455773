<template>
  <div
      id="ws-accessory-modal"
      class="uk-modal-full"
      uk-modal
  >
    <div class="uk-modal-dialog" uk-overflow-auto>
      <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
      <div class="uk-container uk-container-xlarge uk-margin-large-bottom">
        <h1 class="uk-margin-large-top">Zubehörliste für Artikelnummer {{productNumber}}</h1>
        <div v-if="accessoryList.length > 0">
          <div v-for="(item, key) in accessoryList" :key="item.Id">
            <div class="ws-search-ac-item-container uk-grid-medium uk-position-relative" uk-grid>
              <div class="uk-width-auto" v-html="item.imageHtml"></div>
              <div class="uk-width-expand">
                <div class="uk-grid-small" uk-grid>
                  <div class="uk-width-1-1 uk-width-auto@m">
                    <h3 class="uk-margin-remove-bottom">
                      <span v-html="item.name"></span><span v-if="item.articleNumber">&nbsp;|&nbsp;<span v-html="item.articleNumber"></span></span>
                    </h3>
                    <span class="uk-text-nowrap uk-margin-medium-right" v-for="cs in item.csData"><span>{{ cs.keyName }}</span> <span v-html="cs.highlightText"></span></span>
                  </div>
                  <div class="uk-width-1-1 uk-width-expand@m" v-html="item.descShort"></div>
                </div>
              </div>
              <a v-if="item.link" :href="item.link" class="uk-position-cover" :title="item.name"></a>
            </div>
            <hr v-if="key < (accessoryList.length -1)" class="uk-hr">
          </div>
        </div>
        <div v-else>
          <div class="uk-text-center uk-width-1-1" uk-spinner="ratio: 3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useProductStore} from "../../store/productStore";

export default {
  name: "AccessoryList",
  data() {
    return {
      accessoryList: [],
      productNumber: ''
    };
  },
  setup() {
    const productStore = useProductStore();
    return {
      productStore
    }
  },
  watch: {
    'productStore.getAccessoryList'() {
      this.updateAccessoryList();
    }
  },
  methods: {
    updateAccessoryList() {
      this.accessoryList = this.productStore.getAccessoryList;
      this.productNumber = this.productStore.getParentProductNumber
    }
  }
}
</script>