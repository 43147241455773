import { defineStore } from 'pinia'
import ApiService from '../service/ApiService'

export const useProductStore = defineStore(
    'productStore',
    {
        state: () => ({
            accessoryList: [],
            parentProductNumber: ''
        }),
        actions: {
            loadAccessoryList(payload) {
                this.parentProductNumber = payload.productNumber;
                return ApiService.getProductsByIdList(payload.accessoryIds).then(response => {
                    this.accessoryList = response.data;
                    return response;
                })
            },
            resetAccessoryList() {
                this.accessoryList = [];
            }
        },
        getters: {
            getAccessoryList(state) {
                return state.accessoryList;
            },
            getParentProductNumber(state) {
                return state.parentProductNumber;
            }
        }
    },
)