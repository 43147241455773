<template>
  <div id="ws-search-container">
    <form class="uk-search uk-search-navbar uk-width-1-1" action="/search">
      <span class="uk-search-icon-flip" uk-search-icon></span>
      <input id="searchToggle" v-model="searchTerm" name="term" class="uk-width-1-1 uk-search-input" @keyup="fetchSearchResults" type="search"
          :placeholder="'Artikelnr. / Produkt eingeben'" autocomplete="off" autofocus/>
    </form>
  </div>
  <SearchResultList :searchResultList="searchResultList"></SearchResultList>
</template>

<script>
import ApiService from "../../service/ApiService";
import {drop} from "uikit";
import SearchResultList from "./SearchResultList.vue";

export default {
  name: "SearchAutocomplete",
  components: {SearchResultList},
  data() {
    return {
      searchTerm: '',
      searchResultList: [],
      timer: null
    }
  },
  methods: {
    fetchSearchResults() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        ApiService.getProductSearchResult({term: this.searchTerm}).then(response => {
          this.searchResultList = response.data
          if (this.searchResultList.length > 0) {
            drop('#ws-search-ac').show()
          }
        })

      }, 250)
    }
  },
  computed: {
    showDropDown() {
      return this.searchResultList.length > 0
    }
  }
}
</script>
