import {createApp} from "vue"
// import {createI18n} from 'vue-i18n'
import {createPinia} from "pinia"
import SearchAutocomplete from "./components/search/SearchAutocomplete.vue";
import AccessoryListButton from "./components/product/AccessoryListButton.vue";
import AccessoryList from "./components/product/AccessoryList.vue";

const pinia = createPinia()

const cId = ((id) => {
    return document.getElementById(id)
})

if(cId('searchAutocomplete')) {
    createApp(SearchAutocomplete).mount('#searchAutocomplete');
}

if(cId('ws-accessoryList')) {
    createApp(AccessoryList).use(pinia).mount('#ws-accessoryList');
}

let acList = document.getElementsByClassName('ws-showAccessoryList')
if(acList) {
    for (let element of acList) {
        createApp(AccessoryListButton, {productNumber: element.getAttribute('data-product-number'), accessoryIds: element.getAttribute('data-accessory-ids')}).use(pinia).mount(element);
    }
}